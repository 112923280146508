import { parseISO } from "date-fns";
import React from "react";
import { Button } from "../../atoms/Button/Button";
import { LogoCorner } from "../../atoms/LogoCorner/LogoCorner";
import { ResponsiveContainer } from "../../atoms/ResponsiveContainer/ResponsiveContainer";
import { BlogCard } from "../../molecules/BlogCard/BlogCard";
import { SectionHeading } from "../../molecules/SectionHeading/SectionHeading";
import { VideoModal } from "../../molecules/VideoModal/VideoModal";
import EmblaCarousel from "../../molecules/Slider/EmblaCarousel";
import { EmblaOptionsType } from "embla-carousel-react";
import "./InFocus.scss";

interface Props {
  title: string;
  subtitle: string;
  description: string;
  video: {
    ctaLabel: string;
    modalAriaLabel: string;
    embedHtml: string;
  };
  articles: {
    imageSrc: string;
    title: string;
    date: string;
    url: string;
  }[];
}

/** This probably only works for YouTube videos */
const getEmbedWithAutoplay = (iframeHtml: string | undefined) => {
  return iframeHtml?.replace("?feature=oembed", "?feature=oembed&autoplay=1");
};

export const InFocus: React.FC<Props> = (props) => {
  const emblaCarouselOptions: EmblaOptionsType = {
    align: "start",
  };
  return (
    <div className="in-focus">
      <div className="in-focus__inner">
        <LogoCorner type={"accent"} corner={"tl"} />
        <LogoCorner type={"accent"} corner={"tr"} />
        <LogoCorner type={"accent"} corner={"bl"} />
        <div className="in-focus__intro">
          <SectionHeading
            sectionHeading={props.title}
            subHeading={props.subtitle}
            hideRuler={true}
          />
          <p>{props.description}</p>
          <VideoModal
            renderTrigger={(onClick) => (
              <Button className="in-focus__link is-link" onClick={onClick} aria-label="Open Video">
                {props.video.ctaLabel}
              </Button>
            )}
            dialogAriaLabel={props.video?.modalAriaLabel || ""}
            renderVideo={() => (
              <div
                dangerouslySetInnerHTML={{
                  __html: getEmbedWithAutoplay(props.video?.embedHtml) ?? "",
                }}
              />
            )}
          />
        </div>
        <ResponsiveContainer
          mobile={() => (
            <EmblaCarousel
              options={emblaCarouselOptions}
              arrows={true}
              className="embla-in-focus-carousel">
              {props.articles.map(({ imageSrc, title, date, url }, index) => {
                const size = index === 0 ? "hero" : undefined;
                return (
                  <BlogCard
                    key={index}
                    className="mobile-slider-card embla__slide"
                    size={size}
                    image={imageSrc}
                    title={title}
                    date={parseISO(date)}
                    url={url}
                    target="_self"
                  />
                );
              })}
            </EmblaCarousel>
          )}
          tablet={() => (
            <div className="in-focus__card-container">
              <BlogCard
                size="hero"
                image={props.articles[0].imageSrc}
                title={props.articles[0].title}
                date={parseISO(props.articles[0].date)}
                url={props.articles[0].url}
                target="_self"
              />
              <BlogCard
                image={props.articles[1].imageSrc}
                title={props.articles[1].title}
                date={parseISO(props.articles[1].date)}
                url={props.articles[1].url}
                target="_self"
              />
              <BlogCard
                image={props.articles[2].imageSrc}
                title={props.articles[2].title}
                date={parseISO(props.articles[2].date)}
                url={props.articles[2].url}
                target="_self"
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};
