import React from "react";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import { PulsatingTl } from "./PulsatingTl";
import imageLoader from "../../utils/imageLoader";
import { useGetPartnerProfilePics } from "../../utils/useGetPartnerProfilePics";
import "./LoadingScreen.scss";

export interface LoadingScreenProps {
  profilePics?: Array<string>;
}

const strToPartner = (str: string) => ({ url: str, alt: "" });

export const LoadingScreen = (props: LoadingScreenProps) => {
  const profilePics = useGetPartnerProfilePics((props.profilePics || []).map(strToPartner), 5);
  const { t } = useTranslation("ui");

  return (
    <div className="tl-loading-wrapper is-flex is-flex-direction-column is-align-items-center">
      <div className="tl-loading-animation-wrapper is-flex is-flex-direction-column is-relative is-align-items-center is-justify-content-center">
        <div className="tl-profiles-wrapper">
          {profilePics.map((profilePic, index) => (
            <Image
              key={index}
              src={profilePic.url}
              className={`image image-${index + 1}`}
              width="40"
              height="40"
              alt={t("LoadingScreen_LEImageAlt", "Local experts")}
              loader={imageLoader}
            />
          ))}
        </div>
        <PulsatingTl />
      </div>

      <span className="is-block is-uppercase is-family-sans-serif mb-5">
        {t("LoadingScreen_GiveUsASecond", "Give us a second")}
      </span>
      <h2 className="title has-text-centered is-block mx-auto has-text-weight-normal is-size-3">
        {t(
          "LoadingScreen_FindingYouAnExpert",
          "We're finding you the perfect local expert and sending them your trip details..."
        )}
      </h2>
    </div>
  );
};
