import { useEffect, useState } from "react";
import { getPartnerProfilePics } from "./getPartnerProfilePics";

export interface PartnerImage {
  url: string;
  alt: string;
}

export const useGetPartnerProfilePics = (items: Array<PartnerImage>, count: number = 3) => {
  const [partnersToUse, setPartnersToUse] = useState<Array<PartnerImage>>(items.slice(0, count));

  useEffect(() => {
    if (partnersToUse.length < count) {
      const backupProfilePics = getPartnerProfilePics(count - partnersToUse.length);
      const profilePicsToShow = partnersToUse.concat(
        backupProfilePics.map((imageUrl, index) => {
          return {
            url: imageUrl,
            alt: `LTO ${index}`,
          };
        })
      );
      setPartnersToUse(profilePicsToShow);
    }
  }, [items]);

  return partnersToUse;
};
