import React from "react";
import { Button } from "../../atoms/Button/Button";
import { Icomoon } from "../../atoms/Icon/Icon";
import { BulletList } from "../../molecules/BulletList/BulletList";
import { CountryImage } from "./CountryImage";
import "./FairerPillar.scss";

interface Props {
  countryQuote: string;
  lto: {
    profileImageUrl: string;
    profileImageUrlAlt: string;
    name: string;
    position: string;
  };
  countryImage: string;
  countryImageAlt: string;
  ctaUrl: string;
  dataTrackingId: string;
}

export const FairerPillar: React.FC<Props> = ({
  lto,
  countryImage,
  countryQuote,
  countryImageAlt,
  ctaUrl,
  dataTrackingId,
}) => {
  const bulletListProps = {
    icon: Icomoon.checkmark,
    iconClassname: "has-text-teal",
    items: [
      `By dealing directly with your expert, more of your money goes straight to the local economy`,
      `With fewer people in the planning chain, you get a better trip at better value`,
      `You save money while local communities benefit more from it. It’s a win-win!`,
    ],
    itemClassname: "has-text-navy is-size-4 is-size-5-tablet",
  };

  return (
    <div className="your-trip-pillar">
      <div className="text-info">
        <h3 className="auth-knowledge-title subtitle is-flex is-family-primary is-uppercase has-text-weight-semibold">
          Reimagining Travel
        </h3>
        <h2 className="expertise-title has-text-weight-normal is-family-secondary has-text-navy">
          A fairer way to travel
        </h2>
        <CountryImage
          className="country-image"
          countryQuote={countryQuote}
          lto={lto}
          countryImage={countryImage}
          countryImageAlt={countryImageAlt}
        />
        <div className="bullet-list-container">
          <BulletList {...bulletListProps} />
        </div>
        <Button
          colour="teal"
          icomoonIcon={Icomoon.arrowRight3}
          target="_self"
          href={ctaUrl}
          data-tracking-id={dataTrackingId}
          className="cta-button">
          Get started
        </Button>
      </div>
      <div className="side-wrapper">
        <CountryImage
          className="is-hidden-mobile"
          countryQuote={countryQuote}
          lto={lto}
          countryImage={countryImage}
          countryImageAlt={countryImageAlt}
        />
      </div>
    </div>
  );
};
