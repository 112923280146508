import React from "react";
import { Separator } from "./Separator";

import "./Stepper.scss";

interface Step {
  title: string;
  label?: string;
  isActive?: boolean;
}

interface Props {
  steps: Array<Step>;
}

export const Stepper: React.FC<Props> = ({ steps }) => {
  return (
    <div className="stepper">
      {steps.map((step, i) => {
        const isFirstStep = i === 0;
        const isLastStep = i === steps.length - 1;
        return (
          <React.Fragment key={i}>
            <Step step={step} isFirstStep={isFirstStep} isLastStep={isLastStep} />
            {i < steps.length - 1 && <Separator fixedHeight />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Step = ({
  isFirstStep,
  isLastStep,
  step,
}: {
  isFirstStep: boolean;
  isLastStep: boolean;
  step: Step;
}) => {
  return (
    <div className="step">
      <div className="circle-wrapper">
        <Separator invisible={isFirstStep} />
        <Circle isActive={step.isActive} />
        <Separator invisible={isLastStep} />
      </div>
      <div>
        {step.label && <p className="label primary">{step.label}</p>}
        <p className="title primary">{step.title}</p>
      </div>
    </div>
  );
};

interface CircleProps {
  isActive?: boolean;
}

const Circle = ({ isActive = false }: CircleProps) => {
  return (
    <div className={`stepper-circle ${isActive ? "stepper-circle-active" : ""}`}>
      {isActive && <div className="stepper-inner-circle" />}
    </div>
  );
};
