/* eslint-disable */

import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { Icomoon } from "../../Icon/Icon";
import { StepButton } from "../../StepButton";
import "./NumberStepper.scss";

interface NumberStepperProps {
  className?: string;
  initialValue?: number;
  inputProps: WithRequired<JSX.IntrinsicElements["input"], "name">;
  subtitleText?: string;
  titleText: string;
  plusDataTrackingId?: string;
  minusDataTrackingId?: string;
}

export const NumberStepper: React.FC<NumberStepperProps> = ({
  className,
  initialValue = 0,
  inputProps,
  subtitleText,
  titleText,
  plusDataTrackingId,
  minusDataTrackingId,
}) => {
  const { register, setFocus, watch, setValue } = useFormContext(); // get form context
  const count = +watch(inputProps.name, initialValue);

  const handleFocus = useCallback(
    (e: any) => {
      setFocus(inputProps.name, { shouldSelect: true });
      if (inputProps.onFocus) {
        inputProps.onFocus(e);
      }
    },
    [inputProps]
  );

  // this bit of logic handles setting the value in the input in state on blur
  const handleBlur = useCallback(
    (e: any) => {
      if (count < 0) {
        setValue(inputProps.name, 0);
      }
      if (inputProps.onBlur) {
        // make sure fire the onBlur prop with the event
        inputProps.onBlur(e);
      }
    },
    [inputProps, count]
  );

  return (
    <div className={classNames(className, "number-stepper")}>
      <div className="number-stepper-text">
        <span className={classNames("title-text", { active: !!count })}>{titleText}</span>
        {subtitleText && <span className="subtitle-text">{subtitleText}</span>}
      </div>
      <div className="number-stepper-buttons">
        <StepButton
          icomoon={Icomoon.minus2}
          onClick={() => count > 0 && setValue(inputProps.name, count - 1)}
          className={classNames({ disabled: count === 0 })}
          data-tracking-id={minusDataTrackingId}
        />
        <input
          className={classNames("number-stepper-count", { active: !!count })}
          onFocus={handleFocus}
          step="1"
          type="number"
          defaultValue={initialValue}
          min="0"
          {...inputProps}
          {...register(inputProps.name, {
            onBlur: handleBlur,
          })}
        />
        <StepButton
          icomoon={Icomoon.plus2}
          onClick={() => setValue(inputProps.name, count + 1)}
          data-tracking-id={plusDataTrackingId}
        />
      </div>
    </div>
  );
};
