import React from "react";
import "./Step.scss";

interface Step {
  className?: string;
  description: string;
  Icon: JSX.Element;
  stepNumber: number;
  title: string;
}

export const Step = ({ className = "", stepNumber, Icon, title, description }: Step) => {
  return (
    <div className={`step-wrapper ${className}`}>
      <div className="icon">{Icon}</div>
      <p className="number">Step {stepNumber}</p>
      <p className="title">{title}</p>
      <p className="description" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};
