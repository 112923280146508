import React from "react";
import "./TopInfo.scss";

export const TopInfo = ({ country }: { country: string }) => {
  return (
    <div className="top-info-wrapper">
      <h2 className="is-family-secondary">How much could your trip cost?</h2>
      <p className="is-family-primary">
        All of our trips are completely tailor-made, so the price of a vacation varies.
      </p>
      <p className="is-family-primary">
        To give you an idea of what it might cost, here is what our travelers tend to spend on their{" "}
        {country} trip.
      </p>
    </div>
  );
};
