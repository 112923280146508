import React from "react";
import { LtoQuote } from "../../../molecules/LtoQuote";
import { ItineraryPhoto } from "../../../molecules/ItineraryPhoto";
import "./CountryImage.scss";

interface Props {
  className?: string;
  lto: {
    profileImageUrl: string;
    profileImageUrlAlt: string;
    name: string;
    position: string;
    quote: string;
  };
  countryImages: Array<{
    countryImage: string;
    countryImageAlt: string;
    countryCityName: string;
    countryDayDescription: string;
  }>;
}

export const CountryImage = ({ className = ``, countryImages, lto }: Props): JSX.Element => {
  return (
    <div className={`image-container-tailor-made ${className}`}>
      <div className="country-image-itinerary">
        {countryImages.map((countryImage, index) => (
          <ItineraryPhoto
            key={index}
            showCaret={index === 1}
            day={index + 4}
            countryImage={countryImage}
          />
        ))}
      </div>
      <div className="lto">
        <LtoQuote quote={lto.quote} lto={lto} arrowPosition="top-center" />
      </div>
    </div>
  );
};
