import React from "react";
import { Icomoon } from "../../../atoms/Icon/Icon";
import { BulletList } from "../../../molecules/BulletList/BulletList";
import { Header } from "./Header";
import "./PriceCardA.scss";

interface PriceCard {
  maxPrice: string;
  minPrice: string;
  numberOfTravelers: number;
  tripLength: number;
}

export const PriceCardA = ({ maxPrice, minPrice, numberOfTravelers, tripLength }: PriceCard) => {
  return (
    <div className="price-card-a_wrapper">
      <Header
        numberOfTravelers={numberOfTravelers}
        tripLength={tripLength}
        className="has-background-white"
      />
      <div className="card-body">
        <h3>Our travelers tend to spend</h3>
        <p className="price">
          {minPrice} - {maxPrice}
        </p>
        <p className="no-of-travellers subtitle">FOR {numberOfTravelers} TRAVELERS</p>
      </div>
      <div className="card-features">
        <BulletList
          icon={Icomoon.checkmark}
          itemClassname="card-features-item"
          items={[
            "Bespoke, handcrafted itinerary",
            "Accommodation",
            "In-trip transportation",
            "24/7 in-destination support",
          ]}
        />
        <BulletList
          icon={Icomoon.cross}
          itemClassname="card-features-item"
          iconClassname="has-text-red"
          items={["International flights"]}
        />
      </div>
    </div>
  );
};
