import React from "react";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";

import "./ContentFrame.scss";
import classNames from "classnames";

interface IContentFrame {
  backgroundImage?: string;
  children: React.ReactNode;
  imagePriority?: boolean;
  className?: string;
  contentFrameClassname?: string;
}

export const ContentFrame = ({ backgroundImage, children, imagePriority, className, contentFrameClassname }: IContentFrame) => {
  return (
    <div className={classNames("content-frame", contentFrameClassname)}>
      {backgroundImage && (
        <Image
          src={backgroundImage}
          layout="fill"
          objectFit="cover"
          alt=""
          quality={90}
          loader={imageLoader}
          priority={imagePriority}
        />
      )}
      <div className={classNames("has-background-white has-text-centered is-relative content-frame__frame", className)}>
        {children}
      </div>
    </div>
  );
};
