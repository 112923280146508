import React, { forwardRef } from "react";
import classNames from "classnames";

import "./TextArea.scss";

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isErrored: boolean;
  className?: string;
  dataTrackingId?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(({
  isErrored,
  className,
  dataTrackingId,
  ...props
}, ref) => {
  return (
    <textarea
      ref={ref}
      className={classNames("textarea-atom", {
        "textarea-errored": isErrored,
      }, className)}
      data-tracking-id={dataTrackingId}
      {...props}
    />
  );
});

TextArea.displayName = 'TextArea';
