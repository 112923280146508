import React from "react";
import { useTranslation } from "react-i18next";
import { Trustpilot } from "../../atoms";
import { Locale } from "@travellocal/utils";
import "./ReviewCarousel.scss";

interface ReviewCarouselProps {
  locale: Locale;
  trustpilotFilters?: React.ComponentProps<typeof Trustpilot>["filters"];
}

export const ReviewCarousel = ({ locale, trustpilotFilters }: ReviewCarouselProps) => {
  const { t } = useTranslation("ui");
  return (
    <div className="review-carousel has-background-navy has-text-white">
      <div className="subtitle is-uppercase is-family-primary is-letter-spacing-1 is-5 has-text-centered px-1">
        {t("ReviewCarousel_title", "What our travellers are saying")}
      </div>

      <div className="review-carousel__slider-container mt-7">
        <Trustpilot locale={locale} template="carousel" filters={trustpilotFilters} theme="dark" />
      </div>
    </div>
  );
};
