import React from "react";
import { Button } from "../../../atoms/Button/Button";
import { Icomoon } from "../../../atoms/Icon/Icon";
import "./GetPersonalizedQuote.scss";

interface GetPersonalizedQuoteProps {
  className?: string;
  ctaUrl: string;
  dataTrackingId: string;
}

export const GetPersonalizedQuote = ({
  className = ``,
  ctaUrl,
  dataTrackingId,
}: GetPersonalizedQuoteProps) => {
  return (
    <div className={`get-personalized-quote-wrapper ${className}`}>
      <p className="is-family-secondary">Get a personalized quote...</p>
      <Button
        colour="teal"
        icomoonIcon={Icomoon.arrowRight3}
        target="_self"
        href={ctaUrl}
        data-tracking-id={dataTrackingId}
        className="cta-button">
        Get started
      </Button>
    </div>
  );
};
