import React from "react";
import Image from "next/legacy/image";
import imageLoader from "../../../utils/imageLoader";
import { LtoQuote } from "../../../molecules/LtoQuote";
import "./CountryImage.scss";

interface Props {
  className?: string;
  countryImage: string;
  countryImageAlt: string;
  countryQuote: string;
  lto: {
    profileImageUrl: string;
    profileImageUrlAlt: string;
    name: string;
    position: string;
  };
}

export const CountryImage = ({
  className = ``,
  countryImage,
  countryImageAlt,
  countryQuote,
  lto,
}: Props): JSX.Element => {
  return (
    <div className={`your-trip-wrapper ${className}`}>
      <div className="country-image-wrapper">
        <Image
          src={countryImage}
          alt={countryImageAlt}
          layout="fill"
          objectFit="cover"
          loader={imageLoader}
        />
      </div>
      <LtoQuote className="lto-quote" quote={countryQuote} lto={lto} />
    </div>
  );
};
