import React from "react";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";
import { useGetPartnerProfilePics } from "../../utils/useGetPartnerProfilePics";
import "./LtoImages.scss";

interface Lto {
  profileImageUrl: string;
  profileImageUrlAlt: string;
}

interface LtoImagesProps {
  ltos: Array<Lto>;
  title: string;
  className?: string;
}

const ltoToPartner = (lto: Lto) => ({ url: lto.profileImageUrl, alt: lto.profileImageUrlAlt });
const partnerToLto = (partner: { url: string; alt: string }) => ({
  profileImageUrl: partner.url,
  profileImageUrlAlt: partner.alt,
});

export const LtoImages: React.FC<LtoImagesProps> = ({ className = "", title, ltos }) => {
  const ltoToUse = useGetPartnerProfilePics(ltos.map(ltoToPartner));

  return (
    <div className={`lto-images-int ${className}`}>
      <div className="flex is-flex-direction-column is-align-items-center">
        <div>
          {ltoToUse.map(partnerToLto).map((item, index) => (
            <span className="avatar" key={index}>
              <Image
                src={item.profileImageUrl}
                alt={item.profileImageUrlAlt}
                layout="fill"
                objectFit="cover"
                loader={imageLoader}
              />
            </span>
          ))}
        </div>
        <h2 className="lto-title subtitle is-family-primary is-letter-spacing-1 is-uppercase">
          {title}
        </h2>
      </div>
    </div>
  );
};
