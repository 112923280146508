import React from "react";
import { TravellerStory } from "../../molecules/TravellerStory";
import { SimpleCarrousel } from "../../molecules/SimpleCarrousel";
import "./TravellerStories.scss";

interface Props {
  stories: Array<{
    countryImage: string;
    countryImageAlt: string;
    quote: string;
    travellerName: string;
    description: string;
  }>;
}

export const TravellerStories: React.FC<Props> = ({ stories }) => {
  if (stories.length === 0) {
    return null;
  }

  return (
    <div className="traveller-stories-wrapper">
      <h2 className="traveller-stories-title subtitle has-text-navy is-family-primary is-uppercase has-text-weight-semibold">
        Traveler Stories
      </h2>
      <SimpleCarrousel
        emblaClassname="traveller-story-slider"
        options={{ align: "start", containScroll: "trimSnaps" }}>
        {stories.map((story, index) => {
          return <TravellerStory key={index} {...story} />;
        })}
      </SimpleCarrousel>
    </div>
  );
};
