import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Trustpilot } from "../../atoms";
import "./ReviewsList.scss";

interface Props {
  locale: React.ComponentProps<typeof Trustpilot>["locale"];
  trustpilotFilters?: React.ComponentProps<typeof Trustpilot>["filters"];
  className?: string;
} 

/**
 * A list of reviews.
 * @status stable
 */
export const ReviewsList: React.FC<Props> = ({ className, locale, trustpilotFilters }) => {
  const { t } = useTranslation("ui");

  return (
    <div
      className={classNames(
        "reviews-list columns is-centered",
        className
      )}
    >
      <div className="column is-12 is-10-fullhd has-text-centered">
        <h2 className="subtitle is-uppercase is-family-primary is-letter-spacing-1 is-size-5 my-5">
          {t("ReviewsList_title", "What our travellers are saying")}
        </h2>
        <div className="mt-7">
          <Trustpilot locale={locale} template="carousel" filters={trustpilotFilters} />
        </div>
      </div>
    </div>
  );
};
