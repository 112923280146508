import React from "react";
import { GetPersonalizedQuote } from "./GetPersonalizedQuote";
import { TopInfo } from "./TopInfo";
import { PriceCard } from "../PriceCard";
import "./Pricing.scss";

interface Pricing {
  country: string;
  ctaUrl?: string;
  dataTrackingId: string;
  maxPrice: string;
  minPrice: string;
  numberOfTravelers?: number;
  tripLength?: number;
}

export const Pricing = ({
  country,
  ctaUrl,
  dataTrackingId,
  maxPrice,
  minPrice,
  numberOfTravelers = 2,
  tripLength = 7,
}: Pricing) => {
  return (
    <div className="price-guide-wrapper">
      <div>
        <TopInfo country={country} />
        {ctaUrl && (
          <GetPersonalizedQuote
            className="is-hidden-touch"
            ctaUrl={ctaUrl}
            dataTrackingId={dataTrackingId}
          />
        )}
      </div>
      <div>
        <PriceCard
          minPrice={minPrice}
          maxPrice={maxPrice}
          numberOfTravelers={numberOfTravelers}
          tripLength={tripLength}
        />
      </div>
      {ctaUrl && (
        <GetPersonalizedQuote
          className="is-hidden-desktop"
          ctaUrl={ctaUrl}
          dataTrackingId={dataTrackingId}
        />
      )}
    </div>
  );
};
