import React from "react";
import { MdOutlineLuggage } from "react-icons/md";
import { IoBrushSharp, IoChatbubblesOutline } from "react-icons/io5";
import { Button } from "../../atoms/Button/Button";
import { Step } from "./Components";
import { Separator } from "./Components/Separator";
import { Icomoon } from "../../atoms/Icon/Icon";
import "./HowItWorks.scss";

interface HowItWorks {
  ctaUrl: string;
  dataTrackingId: string;
}

export const HowItWorks = ({ ctaUrl, dataTrackingId }: HowItWorks) => {
  return (
    <div className="step-by-step-wrapper">
      <h3>How it works</h3>
      <div className="steps">
        <Step
          className="step"
          stepNumber={1}
          Icon={<IoBrushSharp size="20px" />}
          description="Let us know about your trip and we’ll find you the perfect local agent."
          title="Tell us your preferences"
        />
        <Separator />
        <Step
          className="step"
          stepNumber={2}
          Icon={<IoChatbubblesOutline size="20px" />}
          description="Collaborate with them to create your perfect itinerary."
          title="Plan with your local agent"
        />
        <Separator />
        <Step
          className="step"
          stepNumber={3}
          Icon={<MdOutlineLuggage size="20px" />}
          description={`Booking with TravelLocal provides secure payment and financial protection through <a href="https://www.asta.org/" target="_blank" rel="noopener noreferrer">ASTA</a>.`}
          title="Book your trip!"
        />
      </div>
      <Button
        colour="teal"
        icomoonIcon={Icomoon.arrowRight3}
        href={ctaUrl}
        target="_self"
        data-tracking-id={dataTrackingId}
        className="cta-button">
        Get started
      </Button>
    </div>
  );
};
