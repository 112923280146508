import React, { CSSProperties } from "react";

import "./Separator.scss";

export const Separator = ({
  invisible = false,
  fixedHeight = false,
}: {
  invisible?: boolean;
  fixedHeight?: boolean;
}) => {
  const styles: CSSProperties = {
    height: fixedHeight ? "75px" : undefined,
    flex: fixedHeight ? undefined : 1,
    visibility: invisible ? "hidden" : "visible",
  };

  return <div className="separator" style={styles} />;
};
