import React from "react";
import { InputWrapper } from "../wrapper";

type TextInputFormProps = {
  label: string;
  labelClassName?: string;
  helpMessage?: string;
  dataTrackingId?: string;
  placeholder?: string;
} & WithRequired<JSX.IntrinsicElements["input"], "name">;

export const TextInputForm = ({
  label,
  labelClassName,
  helpMessage,
  name,
  dataTrackingId,
  placeholder,
  ...props
}: TextInputFormProps) => {
  return (
    <InputWrapper
      label={{
        text: label,
        className: labelClassName,
      }}
      input={{
        input: "input",
        props: {
          name,
          type: "text",
          placeholder: placeholder || label,
          ...props,
        },
      }}
      helpMessage={helpMessage}
      dataTrackingId={dataTrackingId}
    />
  );
};
