import React from "react";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";
import { Icomoon, Icon } from "../../atoms/Icon/Icon";
import "./ItineraryPhoto.scss";

interface ItineraryPhoto {
  countryImage: {
    countryImage: string;
    countryImageAlt: string;
    countryCityName: string;
    countryDayDescription: string;
  };
  day: number;
  showCaret: boolean;
}

export const ItineraryPhoto = ({ countryImage, day, showCaret }: ItineraryPhoto): JSX.Element => {
  return (
    <div className="itineraryPhotoWrapper">
      <p className="itineraryDay">Day {day}</p>
      <div className="photoWrapper">
        <Image
          className="photo"
          src={countryImage.countryImage}
          alt={countryImage.countryImageAlt}
          layout="fill"
          objectFit="cover"
          loader={imageLoader}
        />
        <p className="itineraryCity">{countryImage.countryCityName}</p>
        <p className="itineraryDescription">{countryImage.countryDayDescription}</p>
      </div>
      {showCaret && <Icon icomoon={Icomoon.arrowDown3} className="has-text-teal" />}
    </div>
  );
};
