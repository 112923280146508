import React from "react";
import Image from "next/legacy/image";
import classNames from "classnames";
import { Stamp } from "../../atoms/Stamp/Stamp";
import imageLoader from "../../utils/imageLoader";
import { useGetPartnerProfilePics } from "../../utils/useGetPartnerProfilePics";
import "./PartnerNotice.scss";

interface PartnerImage {
  url: string;
  alt: string;
}

interface Props {
  items: PartnerImage[];
  heading?: string;
  content: string;
  stamp?: "on" | "off";
}

/**
 * A pullout notice to introduce the LTOs voice, usually with hints or nudges to users of what's going on or what to do next.
 *
 */
export const PartnerNotice: React.FC<Props> = ({
  items = [],
  heading,
  content,
  stamp,
}) => {
  const partnersToUse = useGetPartnerProfilePics(items);

  return (
    <div className="partner-notice-wrapper">
      <div
        className={classNames(
          "partner-notice is-flex is-justify-content-center is-align-items-center p-5",
          {
            "has-stamp": stamp === "on",
          }
        )}
      >
        <div className="partner-notice__images">
          {partnersToUse.map((partner, index) => (
            <span className="avatar" key={index}>
              <Image
                src={partner.url}
                alt={partner.alt}
                layout="fill"
                sizes="70px"
                objectFit="cover"
                loader={imageLoader}
              />
            </span>
          ))}
        </div>
        <div className="partner-notice__content">
          {heading && <h3 className="title mb-1">{heading}</h3>}
          {content && <p>{content}</p>}
        </div>
        {stamp === "on" && <Stamp type="elephant" />}
      </div>
    </div>
  );
};

PartnerNotice.defaultProps = {};

PartnerNotice.displayName = "PartnerNotice";
