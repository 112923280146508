import React from "react";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";
import "./TravellerStory.scss";

interface Props {
  countryImage: string;
  countryImageAlt: string;
  quote: string;
  travellerName: string;
  description: string;
  className?: string;
}

export const TravellerStory: React.FC<Props> = ({
  countryImage,
  countryImageAlt,
  quote,
  travellerName,
  description,
  className = ``,
}) => {
  return (
    <div className={`traveller-story ${className}`}>
      <div className="traveller-story-top">
        <div className="image-wrapper">
          <Image
            className="photo"
            src={countryImage}
            alt={countryImageAlt}
            layout="fill"
            objectFit="cover"
            loader={imageLoader}
          />
        </div>
        <p className="quote">{quote}</p>
      </div>
      <div>
        <p className="travellerName">{travellerName}</p>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
