import React from "react";
import { Trustpilot as TrustpilotWidget } from "../../atoms/Trustpilot/Trustpilot";
import "./TrustPilot.scss";

interface Props {
  countryCode: string;
}

export const TrustPilot: React.FC<Props> = ({ countryCode }) => {
  return (
    <div className="trustpilot" id="trustpilot-section">
      <div className="text-wrapper">
        <h2 className="has-text-weight-normal is-family-secondary">
          25,000+ travelers have had a more authentic, tailor-made vacation with us
        </h2>
      </div>
      <div className="widget-wrapper">
        <TrustpilotWidget
          filters={{ "data-tags": `ctr-${countryCode.toLowerCase()}` }}
          locale="en"
          template="carousel"
          theme="dark"
        />
      </div>
    </div>
  );
};
