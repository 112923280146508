import React from "react";
import { useTranslation, Trans } from "react-i18next";
import Image from "next/legacy/image";
import imageLoader from "../../../utils/imageLoader";
import { Button, Dropdown, DropdownBody, DropdownBodyProps, useAppState } from "../../..";

import "./ReimagineTravelDropdown.scss";

const useReimagineLinks = () => {
  /*
   * Since we don't have the translateRoute function here, this is a simpler implementation.
   */
  const { t } = useTranslation("common");
  const { appState } = useAppState();
  const locale = appState?.locale || `en`;

  return [
    {
      label: t(`common:pageName_reimagineOverview`, `Overview`),
      url: `/${locale}${t(`routes:reimagineOverview`, ``)}`,
      nameForTracking: `overview`,
    },
    {
      label: t(`common:pageName_reimagineOurCommitment`, `Our commitment`),
      url: `/${locale}${t(`routes:reimagineOurCommitment`, ``)}`,
      nameForTracking: `commitment`,
    },
    {
      label: t(`common:pageName_reimagineEnvironment`, `Environment`),
      url: `/${locale}${t(`routes:reimagineEnvironment`, ``)}`,
      nameForTracking: `environment`,
    },
    {
      label: t(`common:pageName_reimaginePeopleAndSociety`, `People and society`),
      url: `/${locale}${t(`routes:reimaginePeopleAndSociety`, ``)}`,
      nameForTracking: `peopleAndSociety`,
    },
    {
      label: t(`common:pageName_about`, `About us`),
      url: `/${locale}${t(`routes:aboutUs`, ``)}`,
      nameForTracking: `aboutUs`,
    },
  ];
};

const ReimaginingTravelHoverLink = (props: { children: React.ReactNode, dataTrackingId?: string }) => {
  const { t } = useTranslation("ui");
  return (
    <div>
      <Button element="button" className="is-header-link is-family-primary" data-tracking-id={props.dataTrackingId}>
        {t("ReimagineTravelDropdown_linkLabel", "Reimagining travel")}
      </Button>
      {props.children}
    </div>
  );
};

type ReimagineTravelDropdownBodyProps = React.PropsWithChildren<DropdownBodyProps & ReimagineTravelDropdownProps>;

interface ReimagineTravelDropdownProps {
  dataTrackingId?: string;
}

const ReimagineTravelDropdownBody: React.FC<ReimagineTravelDropdownBodyProps> = (props) => {
  const tabIndex = props.isVisible ? 0 : -1; // If visible, allow tabbing through links
  const reimagineLinks = useReimagineLinks();

  return (
    <DropdownBody {...props} visualStyle="arrow">
      <div className="reimagine-dropdown__body has-background-white">
        <div className="reimagine-dropdown__leftCol">
          <ul>
            {reimagineLinks.map((link, index) => {
              return (
                <li key={index}>
                  <a
                    href={link.url}
                    tabIndex={tabIndex}
                    data-tracking-id={props.dataTrackingId && `${props.dataTrackingId}.${link.nameForTracking}`}
                  >
                    {link.label}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="reimagine-dropdown__rightCol">
          <Image
            src="https://resources.travellocal.com/images/dropdown/reimagine.jpg"
            width={300}
            objectFit="cover"
            layout="fill"
            height={169}
            loader={imageLoader}
            alt=""
          />
          <div>
            <Trans i18nKey="ui:ReimagineTravelDropdown_flyoutLabel">
              Reimagining
              <br />
              travel
            </Trans>
          </div>
        </div>
      </div>
    </DropdownBody>
  );
};

/**
 * Inspiration dropdown on the marketing site.
 *
 */
export const ReimagineTravelDropdown: React.FC<ReimagineTravelDropdownProps> = (props) => {
  const renderTrigger = (triggerProps: { children?: React.ReactNode }) => (
    <ReimaginingTravelHoverLink dataTrackingId={props.dataTrackingId}>
      {triggerProps.children}
    </ReimaginingTravelHoverLink>
  );

  return (
    <Dropdown
      className="reimagine-travel-dropdown"
      Trigger={renderTrigger}
      openOn="click"
      Body={(x) => <ReimagineTravelDropdownBody {...x} />}
      hasShade={false}
    />
  );
};
