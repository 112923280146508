import React from "react";
import classNames from "classnames";
import "./SectionHeading.scss";

interface Props {
  sectionHeading: string;
  subHeading?: string;
  variant?: "default" | "inline";
  children?: React.ReactNode;
  hideRuler?: boolean;
  sectionClassname?: string;
  subheadingClassname?: string;
  rulerClassname?: string;
  headTag?: "h1" | "h2" | "h3";
}

export const SectionHeading: React.FC<Props> = ({
  sectionHeading,
  subHeading,
  children,
  variant = "default",
  hideRuler = false,
  sectionClassname,
  subheadingClassname,
  rulerClassname,
  headTag = "h2",
}: Props) => {
  const HeadingTag = headTag;

  return (
    <div
      className={classNames("section-heading is-flex", {
        "is-flex-direction-column is-align-items-center": variant === "default",
        "is-flex-direction-row": variant === "inline",
      })}>
      <div
        className={classNames("is-flex", {
          "is-justify-content-start is-flex-direction-row-reverse is-align-items-baseline":
            variant === "inline",
          "is-flex-direction-column is-align-items-center": variant === "default",
        })}>
        {subHeading && (
          <span
            className={classNames(
              "subtitle is-uppercase is-size-6 has-text-weight-semibold is-family-primary is-letter-spacing-1 is-block",
              subheadingClassname,
              { "mb-5": variant === "default" },
              { "is-spaced ml-6 mb-1": variant === "inline" }
            )}>
            {subHeading}
          </span>
        )}
        <HeadingTag
          className={classNames(
            "title has-text-weight-light",
            {
              "is-2": headTag !== "h3",
              "is-3": headTag === "h3",
              heading: variant === "default",
            },
            sectionClassname
          )}>
          {sectionHeading}
        </HeadingTag>
      </div>
      {children}
      <hr
        className={classNames(rulerClassname, {
          "mt-6 mb-3": variant === "default",
          "is-hidden": hideRuler || variant === "inline",
        })}
      />
    </div>
  );
};

SectionHeading.displayName = "SectionHeading";
