import React from "react";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";
import { Icomoon } from "../../atoms/Icon/Icon";
import { BulletList } from "../../molecules/BulletList/BulletList";
import "./Sustainability.scss";

interface Props {
  country: string;
  countryImage: string;
  countryImageAlt: string;
}

export const Sustainability: React.FC<Props> = ({ country, countryImage, countryImageAlt }) => {
  const bulletListProps = {
    icon: Icomoon.checkmark,
    iconClassname: "has-text-teal",
    items: [
      `Our local agents work to avoid overtourism, while you enjoy space away from the crowds`,
      `We put local people in charge of local tourism`,
      `We support our local agents, training them in ethical practices, sales and environmental responsibility`,
    ],
    itemClassname: "has-text-navy is-size-4 is-size-5-tablet",
  };

  return (
    <div className="sustainability">
      <div className="image-wrapper">
        <div className="img">
          <Image
            src={countryImage}
            alt={countryImageAlt}
            layout="fill"
            objectFit="cover"
            loader={imageLoader}
          />
        </div>
      </div>
      <div className="text-wrapper">
        <h2 className="has-text-weight-normal is-family-secondary">
          Helping {country} to flourish for generations to come
        </h2>
        <div className="bullet-list-container">
          <BulletList {...bulletListProps} />
        </div>
      </div>
    </div>
  );
};
