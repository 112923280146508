import React from "react";
import { PriceCardA } from "./variantA";
import { PriceCardB } from "./variantB";

interface PriceCard {
  maxPrice: string;
  minPrice: string;
  numberOfTravelers: number;
  tripLength: number;
  variant?: "a" | "b";
}

export const PriceCard = ({ variant = "a", ...rest }: PriceCard) => {
  return variant === "a" ? <PriceCardA {...rest} /> : <PriceCardB {...rest} />;
};
