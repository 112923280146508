import React from "react";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";
import "./LtoQuote.scss";

interface LtoQuoteProps {
  className?: string;
  arrowPosition?: "right-top" | "top-left" | "top-center";
  quote: string;
  lto: {
    profileImageUrl: string;
    profileImageUrlAlt: string;
    name: string;
    position: string;
  };
}

export const LtoQuote = ({
  arrowPosition = "right-top",
  className = ``,
  quote,
  lto,
}: LtoQuoteProps) => {
  return (
    <div className={`lto-quote-wrapper ${className} arrow-${arrowPosition}`}>
      <h3 className="lto-text is-italic is-family-secondary">{quote}</h3>
      <div className="lto-profile-wrapper">
        <span className="avatar">
          <Image
            src={lto.profileImageUrl}
            alt={lto.profileImageUrlAlt}
            layout="fill"
            objectFit="cover"
            loader={imageLoader}
          />
        </span>
        <div className="lto-profile-text">
          <h4 className="lto-name is-family-secondary">{lto.name}</h4>
          <span className="lto-role is-family-primary subtitle is-uppercase">{lto.position}</span>
        </div>
      </div>
    </div>
  );
};
