import React from "react";
import classNames from "classnames";
import { Icomoon, Icon } from "../Icon/Icon";
import "./StepButton.scss";

interface StepButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  icomoon: Icomoon;
}

export const StepButton: React.FC<StepButtonProps> = ({ className, icomoon, ...buttonProps }) => {
  return (
    <button className={classNames(className, "step-button")} {...buttonProps} type="button">
      <Icon icomoon={icomoon} />
    </button>
  );
};
