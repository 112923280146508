export const getPartnerProfilePics = (amount?: number) => {
  return [
    "https://d3icb70lnx3c24.cloudfront.net/150x150/3307d5a708b5d6a4.jpeg",
    "https://d3icb70lnx3c24.cloudfront.net/150x150/2b19b229566de428.jpeg",
    "https://d3icb70lnx3c24.cloudfront.net/150x150/b2d982134f5b6b35.jpg",
    "https://d3icb70lnx3c24.cloudfront.net/150x150/041f27e3b16107cb.jpeg",
    "https://d3icb70lnx3c24.cloudfront.net/150x150/b64996c1ef5acd6d.jpeg",
    "https://d3icb70lnx3c24.cloudfront.net/150x150/3307d5a708b5d6a4.jpeg",
    "https://d3icb70lnx3c24.cloudfront.net/150x150/2b19b229566de428.jpeg",
    "https://d3icb70lnx3c24.cloudfront.net/150x150/b2d982134f5b6b35.jpg",
    "https://d3icb70lnx3c24.cloudfront.net/150x150/041f27e3b16107cb.jpeg",
    "https://d3icb70lnx3c24.cloudfront.net/150x150/b64996c1ef5acd6d.jpeg",
  ].slice(0, amount);
};
