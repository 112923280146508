import React from "react";
import { useAppState } from "../../utils";
import { useTranslation } from "react-i18next";
import { Locale } from "@travellocal/utils";

export const trustCountries = ["en-gb", "en", "de"];
interface TrustProps {
  language?: Locale;
  dataTrackingIds?: {
    travelife?: string;
    reviews?: string;
  };
}

import "./Trust.scss";
import { TrustLogo } from "./TrustLogo";
import { Trustpilot } from "../../atoms/Trustpilot/Trustpilot";

export const Trust = ({ language, dataTrackingIds }: TrustProps) => {
  const { t } = useTranslation("ui");
  const { appState } = useAppState();
  const locale = language || appState?.locale || "en-gb";

  if (!trustCountries.includes(locale)) {
    return null;
  }

  return (
    <div className="trust p-5 p-6-desktop">
      <div className="trust__inner is-flex is-justify-content-center is-align-items-center">
        <div className="subtitle is-uppercase is-family-primary is-letter-spacing-1 is-size-5 has-text-centered">
          {t("Trust_title", "Book with confidence")}
        </div>
        <TrustLogo dataTrackingIds={dataTrackingIds} locale={locale} />
        <div className="trust__trustpilot">
          <Trustpilot locale={locale} template="mini" height="120px" />
        </div>
      </div>
    </div>
  );
};

Trust.displayName = "Trust";
