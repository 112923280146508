import React from "react";
import { Button } from "../../atoms/Button/Button";
import { Icomoon } from "../../atoms/Icon/Icon";
import { BulletList } from "../../molecules/BulletList/BulletList";
import { CountryImage } from "./CountryImage";
import "./YourTripPillar.scss";

interface Props {
  country: string;
  lto: {
    profileImageUrl: string;
    profileImageUrlAlt: string;
    name: string;
    position: string;
  };
  countryImage: string;
  countryImageAlt: string;
  countryQuote: string;
  cta?: string;
  ctaUrl: string;
  dataTrackingId: string;
}

export const YourTripPillar: React.FC<Props> = ({
  country,
  lto,
  countryImage,
  countryImageAlt,
  countryQuote,
  cta = "Get started",
  ctaUrl,
  dataTrackingId,
}) => {
  const bulletListProps = {
    icon: Icomoon.checkmark,
    iconClassname: "has-text-teal",
    items: [
      `We carefully handpick only the best, English-speaking local agents, who have welcomed travelers from around the world`,
      `Your expert will use their intimate local knowledge to give you the most immersive ${country} experience`,
      `All the knowledge of a local agent, with support from the TravelLocal team for your peace of mind`,
    ],
    itemClassname: "has-text-navy is-size-4 is-size-5-tablet",
  };

  return (
    <div className="your-trip-pillar">
      <div className="text-info">
        <h3 className="auth-knowledge-title subtitle is-family-primary is-uppercase has-text-weight-semibold">
          Authentic Knowledge
        </h3>
        <h2 className="expertise-title has-text-weight-normal is-family-secondary">
          Your trip, their local expertise
        </h2>
        <CountryImage
          className="country-image"
          lto={lto}
          countryImage={countryImage}
          countryImageAlt={countryImageAlt}
          countryQuote={countryQuote}
        />
        <div className="bullet-list-container">
          <BulletList {...bulletListProps} />
        </div>
        <Button
          colour="teal"
          target="_self"
          icomoonIcon={Icomoon.arrowRight3}
          href={ctaUrl}
          data-tracking-id={dataTrackingId}
          className="cta-button">
          {cta}
        </Button>
      </div>
      <div className="side-wrapper">
        <CountryImage
          className="is-hidden-mobile"
          lto={lto}
          countryImage={countryImage}
          countryImageAlt={countryImageAlt}
          countryQuote={countryQuote}
        />
      </div>
    </div>
  );
};
