import React, { useEffect, useState } from "react";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import { CircleArrow } from "../../atoms/CircleArrow/CircleArrow";
import classNames from "classnames";

interface SimpleCarrouselProps {
  emblaClassname?: string;
  children: React.ReactNode;
  options?: EmblaOptionsType;
}

/**
 * Keep it simple, stupid.
 */
export const SimpleCarrousel = ({
  emblaClassname = `embla`,
  children,
  options,
}: SimpleCarrouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const scrollPrev = () => emblaApi && emblaApi.scrollPrev();
  const scrollNext = () => emblaApi && emblaApi.scrollNext();

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setPrevBtnEnabled(emblaApi.canScrollPrev());
      setNextBtnEnabled(emblaApi.canScrollNext());
    };

    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);
    onSelect();

    return () => {
      emblaApi.off("select", onSelect);
      emblaApi.off("reInit", onSelect);
    };
  }, [emblaApi]);

  return (
    <div>
      <div className={emblaClassname} ref={emblaRef}>
        <div className={`${emblaClassname}__container`}>
          {Array.isArray(children) &&
            children.map((child, i) => {
              return (
                <div className={`${emblaClassname}__slide`} key={i}>
                  {child}
                </div>
              );
            })}
        </div>
        <div
          className="nav-buttons-container"
          style={{
            display: "flex",
            gap: `50px`,
            paddingBottom: `20px`,
            paddingRight: `15px`,
            justifyContent: "flex-end",
            width: "100%",
          }}>
          <CircleArrow
            onClick={scrollPrev}
            disabled={!prevBtnEnabled}
            className={classNames({
              "is-transparent": !prevBtnEnabled,
            })}
            dir="left"
            theme="dark"
          />
          <CircleArrow
            onClick={scrollNext}
            disabled={!nextBtnEnabled}
            className={classNames({
              "is-transparent": !nextBtnEnabled,
            })}
            dir="right"
            theme="dark"
          />
        </div>
      </div>
    </div>
  );
};
