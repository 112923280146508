import React from "react";
import { AstaLogo } from "../../atoms/AstaLogo/AstaLogo";
import { Button } from "../../atoms/Button/Button";
import { Icomoon } from "../../atoms/Icon/Icon";
import { BulletList } from "../../molecules/BulletList/BulletList";
import { CountryImage } from "./CountryImage";
import "./TailorMadeTravelPillar.scss";

interface Props {
  lto: {
    profileImageUrl: string;
    profileImageUrlAlt: string;
    name: string;
    position: string;
    quote: string;
  };
  countryImages: Array<{
    countryImage: string;
    countryImageAlt: string;
    countryCityName: string;
    countryDayDescription: string;
  }>;
  ctaUrl: string;
  dataTrackingId: string;
}

export const TailorMadeTravelPillar: React.FC<Props> = ({
  lto,
  countryImages,
  ctaUrl,
  dataTrackingId,
}) => {
  const bulletListProps = {
    icon: Icomoon.checkmark,
    iconClassname: "has-text-teal",
    items: [
      `Plan your trip exactly the way you want it, hassle-free`,
      `24/7 in-destination support`,
      `Peace of mind with financial protection from <a href="https://www.asta.org/" target="_blank" rel="noopener noreferrer">ASTA</a>`,
    ],
    itemClassname: "has-text-navy is-size-4 is-size-5-tablet",
  };

  return (
    <div className="tailor-made-pillar">
      <div className="text-info">
        <h3 className="auth-knowledge-title subtitle is-family-primary is-uppercase has-text-weight-semibold">
          Trips Unique to You
        </h3>
        <h2 className="expertise-title has-text-weight-normal is-family-secondary">
          Tailor-made travel, made easy
        </h2>
        <CountryImage className="is-hidden-tablet" lto={lto} countryImages={countryImages} />
        <div className="bullet-list-container">
          <BulletList {...bulletListProps} />
          <AstaLogo height={58} width={158} type="dark" className="ml-3" />
        </div>
        <Button
          colour="teal"
          icomoonIcon={Icomoon.arrowRight3}
          target="_self"
          href={ctaUrl}
          data-tracking-id={dataTrackingId}
          className="cta-button">
          Get started
        </Button>
      </div>
      <div className="side-wrapper">
        <CountryImage className="is-hidden-mobile" lto={lto} countryImages={countryImages} />
      </div>
    </div>
  );
};
