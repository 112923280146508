import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { BlogCard, BlogCardProps } from "../../molecules/BlogCard/BlogCard";
import { CardLayout } from "../../molecules/CardLayout/CardLayout";
import { LocationCard } from "../../molecules/LocationCard/LocationCard";
import "./BlogRelatedLinks.scss";

export interface BlogRelatedLinksProps {
  sectionHeading: string;
  countries: {
    slug: string;
    colour: string;
    name: string;
    image: string;
  }[];
  sectionCountriesIntro: string;
  sectionArticlesIntro: string;
  articles: BlogCardProps[];
  backgroundColour: "white" | "sage";
}

export const BlogRelatedLinks: React.FC<BlogRelatedLinksProps> = ({
  sectionHeading,
  countries,
  sectionCountriesIntro,
  sectionArticlesIntro,
  articles = [],
  backgroundColour,
}) => {
  const { t } = useTranslation();

  const doesNotHaveContent = countries.length === 0 && articles.length === 0;
  if (doesNotHaveContent) {
    return null;
  }

  return (
    <section
      className={classNames("blog-articles py-7 is-fluid", {
        "blog-articles__coloured-background": backgroundColour === "sage",
      })}>
      <div className="container">
        <div className="blog-articles__heading mb-5">
          <h2 className="title is-2">{sectionHeading}</h2>
        </div>

        {countries.length > 0 && (
          <div className="overflow-iphone-fix">
            <div className="blog-articles__heading mb-5">
              <p>{sectionCountriesIntro}</p>
            </div>
            <CardLayout layout="carousel">
              {countries.map((country) => {
                const href = t("routes:destinationsByCountry", "/destinations/{{country}}", {
                  country: country.slug,
                });
                return (
                  <div key={country.slug} style={{ width: "150px" }}>
                    <LocationCard
                      size="small"
                      color={country.colour}
                      title={country.name}
                      image={{
                        altText: country.name,
                        sourceUrl: country.image,
                      }}
                      href={href}
                    />
                  </div>
                );
              })}
            </CardLayout>
          </div>
        )}

        {articles.length > 0 && (
          <div>
            <div className="blog-articles__heading mb-5">
              <p>{sectionArticlesIntro}</p>
            </div>
            <div className="blog-articles__articles columns is-flex-wrap-wrap">
              {articles.slice(0, 4).map((article, index) => (
                <div className="column is-flex" key={index}>
                  <BlogCard {...article} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

BlogRelatedLinks.displayName = "BlogRelatedLinks";
