import React from "react";
import Image from "next/legacy/image";
import imageLoader from "../../../utils/imageLoader";
import { LtoQuote } from "../../../molecules/LtoQuote";
import "./CountryImage.scss";

interface Props {
  className?: string;
  countryImage: string;
  countryImageAlt: string;
  countryQuote: string;
  lto: {
    profileImageUrl: string;
    profileImageUrlAlt: string;
    name: string;
    position: string;
  };
}

export const CountryImage = ({
  className = ``,
  countryImage,
  countryImageAlt,
  countryQuote,
  lto,
}: Props): JSX.Element => {
  return (
    <div className={`image-container-fairer ${className}`}>
      <div className="country-image-int">
        <Image
          src={countryImage}
          alt={countryImageAlt}
          layout="fill"
          objectFit="cover"
          loader={imageLoader}
        />
      </div>
      <LtoQuote arrowPosition="top-left" quote={countryQuote} lto={lto} />
    </div>
  );
};
