import React, { useCallback } from "react";
import { Icomoon, Icon } from "../../atoms/Icon/Icon";
import { Stamp } from "../../atoms/Stamp/Stamp";
import { Link } from "../../atoms/Link/Link";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";
import { LtoImages } from "../../molecules/LtoImages";
import { useGetPartnerProfilePics } from "../../utils/useGetPartnerProfilePics";
import "./CountryHeader.scss";

interface Lto {
  profileImageUrl: string;
  profileImageUrlAlt: string;
}

interface Props {
  title: string;
  subtitle: string;
  country: string;
  findOutHowCta?: string;
  scrollToId?: string;
  ltos: Array<Lto>;
  countryImage: string;
  countryImageAlt: string;
}

const ltoToPartner = (lto: Lto) => ({ url: lto.profileImageUrl, alt: lto.profileImageUrlAlt });
const partnerToLto = (partner: { url: string; alt: string }) => ({
  profileImageUrl: partner.url,
  profileImageUrlAlt: partner.alt,
});

export const CountryHeader: React.FC<Props> = ({
  title,
  subtitle,
  country,
  findOutHowCta = `Find out how`,
  scrollToId,
  ltos,
  countryImage,
  countryImageAlt,
}) => {
  const ltoToUse = useGetPartnerProfilePics(ltos.map(ltoToPartner));

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (!scrollToId) {
        return;
      }
      const element = document.getElementById(scrollToId);

      if (element) {
        window.scrollTo({
          top: element.offsetTop - 100,
          behavior: "smooth",
        });
      }
    },
    [scrollToId]
  );

  return (
    <div className="country-header">
      <div className="text-info">
        <h1
          className="country-text has-text-weight-normal is-family-secondary has-text-navy"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className="connect-text has-text-weight-normal is-family-primary has-text-elephant"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
        {scrollToId && (
          <div className="link-wrapper has-text-weight-normal is-family-primary is-flex is-align-items-center">
            <Link onClick={handleClick} className="link" href="#">
              <span>{findOutHowCta}</span>
              <Icon icomoon={Icomoon.arrowDown3} className="pl-2 arrow-icon" />
            </Link>
          </div>
        )}
      </div>
      <div className="side-wrapper">
        <div className="country-image-wrapper">
          <Image
            src={countryImage}
            alt={countryImageAlt}
            loader={imageLoader}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
          <Stamp type="light" className="stamp" />
        </div>
        <LtoImages
          className="lto-images"
          title={`Our local agents in ${country}`}
          ltos={ltoToUse.map(partnerToLto)}
        />
      </div>
    </div>
  );
};
