import React from "react";
import { Icomoon } from "../../../atoms/Icon/Icon";
import { BulletList } from "../../../molecules/BulletList/BulletList";
import { Header } from "./Header";
import "./PriceCardB.scss";

interface PriceCard {
  maxPrice: string;
  minPrice: string;
  numberOfTravelers: number;
  tripLength: number;
}

export const PriceCardB = ({ maxPrice, minPrice, numberOfTravelers, tripLength }: PriceCard) => {
  return (
    <div className="price-card-b_wrapper">
      <div className="card-body">
        <p className="based-on">Based on what other travelers tend to spend...</p>
        <h3>Your trip might cost between</h3>
        <p className="price">
          {minPrice} - {maxPrice}
        </p>
        <p className="no-of-travellers subtitle">FOR {numberOfTravelers} TRAVELERS</p>
        <Header numberOfTravelers={numberOfTravelers} tripLength={tripLength} />
      </div>
      <div className="final-text">
        <p>Of course all our trips are tailor-made so ultimately the budget is up to you!</p>
      </div>
      <div className="card-features is-flex is-flex-direction-column is-gap-6">
        <div>
          <h2 className="is-family-secondary mb-4 is-size-5">Included</h2>
          <BulletList
            icon={Icomoon.checkmark}
            itemClassname="card-features-item"
            items={[
              "Bespoke, handcrafted itinerary",
              "Accommodation",
              "In-trip transportation",
              "Excursions and experiences",
              "24/7 in-destination support",
              "Financial protection with ASTA",
            ]}
          />
        </div>
        <div>
          <h2 className="is-family-secondary mb-4 is-size-5">Not included</h2>
          <BulletList
            icon={Icomoon.cross}
            itemClassname="card-features-item"
            iconClassname="has-text-red"
            items={["International flights", "Travel Insurance", "Visa charges", "Tips"]}
          />
        </div>
      </div>
    </div>
  );
};
